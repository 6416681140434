
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from './Layout';
import Landing from './pages/Landing';
import NoPages from './pages/NoPages';
import Inquiry from './pages/Inquiry';
import Blog from './pages/Blog';
import Resume from './pages/Resume';
import NavBar from './components/NavBar';

import { Authenticator} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import './App.css';


Amplify.configure(awsExports);



/** Give rise to Routes used! */
function App() {
  return (
    <div className="App">
      <Authenticator.Provider>
      <BrowserRouter>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="blog" element={<Blog />} />
            <Route path="inquiry" element={<Inquiry />} />
            <Route path="resume" element={<Resume />} />
            <Route path="*" element={<NoPages />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </Authenticator.Provider>
    </div>
  );
}

export default App;
