import React, { useEffect, useState } from "react";
import './css/blog.css'

const BlogPost = (props) => {
  const [show, setShow] = useState(false);
  const [buttonM, setButtonM] = useState(false);

  const taglsist = props.tag;
  const imagelink = props.imagelink;
  let post = props.post.toString();
  const [preview, setPreview] = useState('')
  const [body, setBody] = useState('')

  useEffect(()=> {
    setPreview(post.substring(0, 80));
    setBody(post);
  },[post]);


  return (
    <div className="blog-post">
      <div className="display">
        <img className='post-img' src={imagelink} alt='s'/>
        <div className="blog-post-body">
          <h4>{props.title}</h4>
            <div id="post-prev" style={{height: show ? '0' : 'fit-content'}}>
              {preview}
            </div>
            <div id="post-body" style={{minHeight: show ? '200px' : '0' }}>
              {body}
            </div>
        </div>
      </div>
     
      
      <div style={{color:"rosybrown"}}>
            {taglsist.map((tag) => ("#" + tag + "  "))}
          </div>
      <button style={{float:"right", backgroundColor: buttonM ? "var(--bg-comp)" : ""}} onClick={(e) => {
          setShow(!show);
          setButtonM(!buttonM);
        }}
        className="btn">
        {buttonM ? "READ LESS" : "READ MORE"}
      </button>

    </div>
  )
}

const BlogPostSet = (props) => {

  return (
    <div className="blog-set">
      {props.bloglist.map((blog) => (<BlogPost key={blog.id} title={blog.title} post={blog.post} tag={blog.tag} imagelink={blog.imagelink}/> )).reverse()}
    </div>
  );
}




function Blog (){

    const bloglist = [
      { id: 1,
        title:"First blog post", 
        post:`I want to talk about the anxiety surrounding getting a job after college.
        Everyone will say the first step is to get over your imposter syndrome and just go for it. They are right... 
        However, I find taking the leap from the top of the seemingly endless staircase we call college unnatural and frankly unsafe.
        See college made you suffer; it wanted you to bleed. Now that your veins run dry it tells
        you to jump as if you got more to give. Perhaps I am just burnt out from being burnt out. I am sure I am not
        the only one that feels this way. But as many before me have picked themselves back up I will not let this stop me.
        If I had learned anything from college is that I am resilient and I am great at suffering`, 
        tag:["blog","personal","job"],
        imagelink:"/images/post1.svg"
      }

    ]

    return(
      <div className="blog-container">
        <BlogPostSet bloglist={bloglist}/>
      </div>
    );
}

export default Blog;