import React, { useEffect, useState } from 'react'
import './css/resume.css'
import { Storage } from "@aws-amplify/storage"


const Project = (props) => {
    return (
        <div className='project-post-body' style={{backgroundImage: 'url('+props.image+')'}}>
            <div className='post-section'>
                <h5>{props.name}</h5>
            </div>
            <div className='post-section font-small'>
                {props.desc}
                
                <div style={{textAlign:'center'}}>
                    <a href={props.link} target='_blank' rel="noopener noreferrer">
                        <i className="fa-solid fa-link"></i>
                    </a>
                </div>
            </div>   
        </div>
    );
}


const Resume = () => {
    const [resume_pdf, setResume] = useState('');
    const projectlist = [
        {   
            id: 1,
            name:"Project Perennial",
            desc: `IoT plant watering system with an integrated WebApp.
                    Users are able to login to the app and look at the current and
                    previous watering sessions. Users are able to check the moisture
                    content of the plant. Plants are watered based on moisture level.
                    Other features include date-time configuration of watering time.`,
            link: "https://github.com/tuyet-pham/Project-Perennial",
            image:"/images/plant.svg"
        },
        {   
            id: 2,
            name:" Scholarship Scrapper",
            desc: `Web Application that lets students search available scholarship 
                    pertaining to their particular field. The App scrapes scholarships,
                    from a particular website that offers scholarships, intermittently
                    to avoid IP black listing. The App allows students to filter scholarships 
                    based on tags, keywords, ending dates and required GPA.`,
            link: "https://github.com/tuyet-pham/Scholarship-Scrapper",
            image:"/images/scholar.svg"
        },
        {   
            id: 3,
            name:" Machine Learning Stocks",
            desc: `A ML application that predicts whether the stock will increase in value
                    by looking at it's past trends. The goal of this project was to 
                    implement the Random Forest Regression algorithm.`, 
            link: "https://github.com/tuyet-pham/Machine-LearningStocks",
            image:"/images/stock.svg"
        },
        {   
            id: 4,
            name:"Nail Salon Website",
            desc: `Commisioned multi-page Website that is hosted by AWS AMP. Currently No back-end. 
                    Future goals for the website is to implement promotional feature 
                    via email or text. The site is built with React.JS`, 
            link: "https://www.5starnailspa-wc.com/",
            image:""
        },
    ]


    const getData = async () => {
        try {
            const res = await Storage.get("Tuyet_Pham_RES.pdf", 
            {level: "public"}
            )
                
            setResume(res);
        }
        catch(e){
            console.log(e);
        }
    }
    
    useEffect(()=> {
        getData();
    },[getData]);


    return(
        <div className='resume-container'>
            <div className='resume-div' id='resume_pdf'>
                    <img src={'/images/resume.svg'} alt="resume-icon"/>
                    <a className='btn-res' href={resume_pdf} target="_blank" rel="noopener noreferrer">
                        Resume PDF
                    </a>

            </div>
            <div className='project-container'>
                <h4 style={{color:'white', marginBottom:'20px'}}>Projects</h4>
                <div className='projects-div' id='projects'>
                   {projectlist.map((list) => (<Project key={list.id} desc={list.desc} link={list.link} image={list.image} name={list.name}/>))}
                </div>
            </div>
            <div>
            </div>
        </div>
    )
};

export default Resume;