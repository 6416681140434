import React from "react";
import { Link } from "react-router-dom";
import './css/footer.css'
import { useAuthenticator } from "@aws-amplify/ui-react";


const Footer = () => {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    
    return(
        <div className="footer">
            <div className="footer-div">
                <ul>
                    <li>
                        {'Quick Links'}
                        <hr/>
                    </li>
                    <li>
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li>
                        <Link to={'/blog'}>Blog</Link>
                    </li>
                    <li>
                        <a href={'/resume#resume_pdf'}>Resume</a>
                    </li>
                    <li>
                        <a href={'/resume#projects'}>Projects</a>
                    </li>
                </ul>
            </div>
            <div className="footer-div">
                <ul>
                    <li>
                        {'Contact'}
                        <hr/>
                    </li>
                    <li>
                        <a href='https://github.com/tuyet-pham' target='_blank' rel="noopener noreferrer">
                            <i className="fa-brands fa-github"></i> Github
                        </a>
                    </li>
                    <li>
                        <a href="mailto:deviit.inc@gmail.com" target='_blank' rel="noopener noreferrer">
                            <i className="fa-solid fa-envelope"></i> Email
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/tuyet-pham/" target='_blank' rel="noopener noreferrer">
                            <i className="fa-brands fa-linkedin"></i> LinkedIn
                        </a>
                    </li>
                </ul>
            </div>
            <div className="footer-div" style={{display: authStatus === 'authenticated' ? "inline" : "none"}}>
                <ul>
                    <li>
                        {'Portal'}
                        <hr/>
                    </li>
                    <li>
                        Tech Support
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;