import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import './css/navbar.css'




const NavBar = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const breakpoint = 620;
        const [show, setShow] = useState(false);
        // hook below is only reevaluated when `user` changes
        

        useEffect(() => {
                window.addEventListener("resize", () => setWidth(window.innerWidth))
        });

        return width > breakpoint ? (
                <div className="nav-body">
                <nav>
                <ul className="nav">
                        <NavLink
                                to="/"
                                className="nav-item">
                                Deviit
                        </NavLink>
                        <NavLink 
                                to="/blog"
                                className="nav-item">
                                Blog
                        </NavLink>
                        <NavLink
                                to="/resume"
                                className="nav-item">
                                Resume
                        </NavLink>
                        <NavLink
                                to="/inquiry"
                                className="nav-item">
                                Clients
                        </NavLink>
                        
                        </ul>
                </nav>
        </div>
        )
        :
        (
        <div className="nav-body">
                <nav>
                <button onClick={()=> setShow(!show)} id="ham-button" className="ham-btn">&#9776;</button>
                <ul id="hamburger" className="nav" style={{maxHeight: show ? "500px" : "0px" }}>
                        <NavLink
                                to="/"
                                className="nav-item"
                                >
                                Home
                        </NavLink>
                        <NavLink 
                                to="/blog" 
                                className="nav-item">
                                Blog
                        </NavLink>
                        
                        <NavLink 
                                to="/resume" 
                                className="nav-item">
                                Resume
                        </NavLink>
                
                        <NavLink
                                to="/inquiry"
                                className="nav-item">
                                Clients
                        </NavLink>
                       
                        </ul>
                </nav>
        </div>
        );
}

export default NavBar;