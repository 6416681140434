import React from "react";
import { Link } from "react-router-dom";
import './css/landing.css';



function Landing() {
    

    return ( 
        <div className="landing-container">
            <div className="landing-win">
                
                <div className="landing-win-inner l-w-left">
                    <h2>Tuyet Pham</h2>
                    <br/>
                    <p>
                        I am a Junior Developer who graduated from the University of North Texas with a B.S. in Computer Science and Certified in Security.
                       <br/>
                       <br/>
                       My interests lie in creating middleware applications such as APIs. 
                       I find the complexities in bridging the gap between 2 applications 
                       interesting and more over fun and rewarding.
                       <br/>
                       <br/>
                       My other long-term interest lies in understanding more about quantum computing 
                       and how to utilize it to solve complex problems in computing. 
                       I believe we are at an impasse using binary algorithm. Quantum is likely the key to getting past that impasse.
                    </p>
                </div>
                <div className="landing-win-inner l-w-right">
                    <img src='/images/header.svg' alt="header-img" />
                    <Link className="link" to="inquiry/">
                        <button className="d-btn">For Clients</button>
                    </Link>
                    <Link className="link" to="resume/">
                        <button className="d-btn">For Employers</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Landing;