import React from "react";
import './css/inquiry.css'

function Inquiry (){
    return(
      <div className="inquiry-container" onLoad={()=> { window.scrollTo(0,0)}}>
        <h2>Coming soon!</h2>
      </div>
    );
}

export default Inquiry;