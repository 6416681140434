import React  from "react";
import {Outlet} from "react-router-dom";
import './App.css'
import Footer from "./components/Footer";

const Layout = () => {  
    return (
      <div>
        <Outlet/>
        <Footer/>
      </div>
    );
};
  
export default Layout;